<template>
<div class="container-fluid mt-5 pt-5">
      <div class="page-breadcrumb">
        <div class="col-12">
            <div class="row">
                <div class="col-7 align-self-center pl-4">
                    <h3 class="page-title text-truncate text-dark font-weight-medium mb-1">config. status</h3>
                    <div class="d-flex align-items-center">
                        <nav aria-label="breadcrumb">
                            <ol class="breadcrumb m-0 p-0">
                                <li class="breadcrumb-item"><a href="?app=dashboard.php">dashboard / assistência técnica / config. status</a></li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="container-fluid qsuite-c-fluid-0">
        <div class="col-12">
            <div class="row">
                <div class="col-12 col-md-12 col-lg-6">
                    <div class="card">
                        <div class="card-body pt-4">
                            <form class="px-2 needs-validation" novalidate @submit.prevent="save">
                                <div class="form-body">
                                    <div class="row">
                                        <div class="col-12">
                                            <div class="form-group">
                                                <label>Status</label>
                                                <input type="text" class="form-control" v-model="name" name="name" v-validate="'required'" :class="{'is-invalid': errors.has('name')}">
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="form-group">
                                                <label>Cor de identificação</label>
                                                <input type="color" class="form-control" value="#000000" v-model="color" name="color" v-validate="'required'" :class="{'is-invalid': errors.has('color')}">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-actions">
                                    <div class="text-right">
                                        <button type="submit" class="btn btn-info">Add</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-12 col-lg-6">
                    <div class="col-12">
                        <div class="form-group d-flex">
                            <input type="search" class="form-control campo-procurar" placeholder="Procurar" v-model="search">
                            <button type="submit" class="btn btn-info" @click="goSearch">Buscar</button>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-body pt-4">
                            <form class="needs-validation" novalidate @submit.prevent="update">
                                <div class="form-body">
                                    <div class="table-responsive">
                                        <table class="table no-wrap mb-0">
                                            <thead>
                                                <tr class="border-0">
                                                    <th class="border-0 text-muted pt-0 px-2 text-center"><span>Cadastrados</span></th>
                                                    <th class="border-0 font-weight-medium text-muted"> </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(item, index) in status" :key="item.id">
                                                    <td class="font-weight-medium text-dark border-top-0 px-2">
                                                        <input type="text" id="inputSuccess1" class="form-control"  aria-placeholder="Em andamento" v-model="status[index].name">
                                                    </td>
                                                    <td class="border-top-0 text-muted">
                                                        <select class="custom-select mr-sm-2" v-model="status[index].is_active">
                                                            <option :value="1">Ativa</option>
                                                            <option :value="0">Inativa</option>
                                                        </select>
                                                        <input type="color" class="form-control" v-model="status[index].color">
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div class="row justify-content-center" v-show="status.length == 0">
                                    Nenhum status encontrado.
                                </div>
                                <div class="form-actions" v-show="status.length > 0">
                                    <div class="text-right">
                                        <button type="submit" class="btn btn-info">Atualizar</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>
<script>
import ConfigService from "@/services/resources/ConfigService";
const service = ConfigService.build();

export default {
  data() {
    return {
      name: null,
      color: null,
      status: [],
      search: ''
    };
  },
  methods: {
    goSearch(){
        if(this.search.length == 0)
            this.fetch();

        this.status = this.status.filter(elem => {
            return elem.name.toLowerCase().includes(this.search.toLowerCase());
        });
    },
    save(){
      this.$validator.validateAll().then((result) => {
        if (result) {

        let data = {
          name: this.name,
          color: this.color,
          type: 'STATUS'
        }
        
          service
          .create(data)
            .then(resp => {
                this.$bvToast.toast('Sua configuração foi criada com sucesso!', {
                  title: 'Configuração criada',
                  autoHideDelay: 5000,
                  type: 'success'
                })
                this.name = null;
                this.color = '#000000';
                this.fetch();
            })
            .catch(err => {
              console.log(err)
            })
          }
      });
    },
    checkField(){
        return this.status.filter(elem => {
            return elem.name.length == 0;
        }).length;
    },
    update(){

        if(this.checkField()){
            this.$bvToast.toast('Algum campo está inválido, por favor, verifique.', {
                title: 'Campo inválido',
                autoHideDelay: 5000,
                type: 'danger'
            })
            return;
        }

        let data = {
          id: 0,
          config: this.status
        }
    
        service
        .update(data)
        .then(resp => {
            this.$bvToast.toast('Sua configuração foi atualizada com sucesso!', {
                title: 'Configuração atualizada',
                autoHideDelay: 5000,
                type: 'success'
            })
        })
        .catch(err => {
            console.log(err)
        })
    },
    fetch(){
        let data = {
            type: 'STATUS'
        }

        service
        .search(data)
        .then(resp => {
            this.status = resp;
        })
        .catch(err => {
            console.log(err)
        })
    }

  },
  mounted() {
      this.fetch();
  },
};
</script>
<style scoped lang="scss">
.bg{
  background: #fff;
  padding: 10px;
  border: 1px #ddd solid;
  border-radius: 5px;
}
</style>